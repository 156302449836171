import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
const contentful = require('contentful')
import BlockHeader from '../components/block-header'
import BlockJumpLinks from '../components/block-jump-links'
import BlockTitle from '../components/block-title'
import BlockImage from '../components/block-image'
import BlockText from '../components/block-text'
import BlockRegisterInterest from '../components/block-register-interest'
import BlockVideo from '../components/block-video'
import BlockRowBreak from '../components/block-row-break'
import BlockAccordion from '../components/block-accordion'
import BlockBackground from '../components/block-bg'
import BlockIconAndText from '../components/block-icon-and-text'
import BlockNumberAndText from '../components/block-number-and-text'
import BlockNeedMoreHelpFooter from '../components/block-need-more-help-footer'
import SchemeCheckerIndex from '../pages/scheme-checker'
import BlockIframe from '../components/block-iframe'
import BlockHomePageBanner from '../components/block-home-page-banner'
import BlockHomePageBoxes from '../components/block-home-page-boxes'
import BlockIncident from '../components/block-incident'
import BlockTecDocs from '../components/block-tech-docs'

const Preview = props => {
  const [components, setComponents] = useState([])

  let slug = null

  if (props.location.search && props.location.search.includes('?slug=')) {
    slug = props.location.search.replace('?slug=', '')
    console.log(slug)
  }

  const client = contentful.createClient({
    host: 'preview.contentful.com',
    space: 'tlpgbvy1k6h2',
    accessToken: 'Zr3l46OYsH7hKfpqJBnbR-G87wRn7b0YrLEb5bOfak0',
  })

  let useChildren = false
  let rowOpen = false
  let children = []
  let fields = null

  let rowChildren = []

  useEffect(() => {
    const getContent = async () => {
      const result = await client.getEntries({
        content_type: 'componentPage',
        order: 'sys.createdAt',
        include: 5,
        'fields.slug[in]': slug,
      })

      console.log(result.items[0].fields.components)

      if (
        result &&
        result.items &&
        result.items[0] &&
        result.items[0].fields.components
      )
        setComponents(result.items[0].fields.components)
    }

    getContent()
  }, [slug])

  if (slug && components.length === 0) {
    return <div>No preview found.</div>
  }

  return (
    <Layout hideNewsLetter location={props.location}>
      <div>
        <Helmet title={'Clean Air Preview Page'}>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="component-holder" style={{ background: '#fff' }}>
          {components.map((c, i) => {
            // if draft component
            if (!c.sys.contentType) {
              return
            }
            // if draft component
            if (!c.sys.revision) {
              return
            }
            switch (c.sys.contentType.sys.id) {
              case 'headerBlock':
                let b = <BlockHeader key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentJumpLinks':
                b = <BlockJumpLinks key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b

              case 'componentTitle':
                b = <BlockTitle key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentImage':
                b = <BlockImage key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentText':
                b = <BlockText key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentVideo':
                b = <BlockVideo key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentAccordion':
                b = <BlockAccordion key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentRowBreak':
                b = <BlockRowBreak key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b

              case 'componentStartBackgroundColour':
                useChildren = true
                fields = c.fields
                return

              case 'componentEndBackgroundColour':
                useChildren = false

                const childs = children
                children = []

                const f = fields
                fields = null

                return (
                  <BlockBackground
                    key={`component${i}`}
                    data={f}
                    children={childs}
                  />
                )

              case 'componentRegisterYourInterest':
                b = (
                  <BlockRegisterInterest
                    key={`component${i}`}
                    data={c.fields}
                  />
                )
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b

              case 'componentIconAndText':
                b = <BlockIconAndText key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b

              case 'componentNumberAndText':
                b = <BlockNumberAndText key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b

              case 'componentNeedMoreHelpFooter':
                b = (
                  <BlockNeedMoreHelpFooter
                    key={`component${i}`}
                    data={c.fields}
                  />
                )
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentWizard':
                b = (
                  <div style={{ width: '100%' }} id="checker">
                    <SchemeCheckerIndex key={`component${i}`} />
                  </div>
                )
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentIframe':
                b = <BlockIframe data={c.fields} key={`component${i}`} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentHomePageBanner':
                b = (
                  <div style={{ width: '100%' }} id="banner">
                    <BlockHomePageBanner
                      data={c.fields}
                      key={`component${i}`}
                    />
                  </div>
                )
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'blockHomePageBoxes':
                b = (
                  <div style={{ width: '100%' }} id="boxes">
                    <BlockHomePageBoxes data={c.fields} key={`component${i}`} />
                  </div>
                )
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentIncidentBanner':
                b = (
                  <div style={{ width: '100%' }}>
                    <BlockIncident data={c.fields} key={`component${i}`} />
                  </div>
                )
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b
              case 'componentTechnicalDocumentSection':
                b = <BlockTecDocs key={`component${i}`} data={c.fields} />
                if (useChildren) {
                  children.push(b)
                  return
                }
                return b

              default:
                break
            }
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Preview
